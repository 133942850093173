import { LOADING_DOTS_TEXT } from "../../constants/texts";

export const Preloader = () => {
    return (<>
            <span className="loader" title={LOADING_DOTS_TEXT()}/>
        </>
    );
}

export default Preloader;
