export const RouterLinks = {
	TRAINING_HEROES_GS: "/trainingHeroesGS",
	UPDATE_HERO_POWER: "/updateHeroPower",
	HEROES_WAR_ADD: "/heroesWarAdd",
	GLOBAL_CHAMPIONSHIP_HEROES: "/globalChampionshipHeroes",
	SEARCH_TEAM_PACK: "/searchTeamPack",
	UPDATE_TITAN_POWER: "/updateTitanPower",
	TITANS_WAR_ADD: "/titansWarAdd",
	SEARCH_TEAM_PACK_TITAN: "/searchTeamPackTitan",
	ACCOUNT_REGISTRATION: "/account/registration",
	ACCOUNT_EDIT: "/account/edit",
	WEB_UPLOAD_SCREENS_MS: "/webUploadScreensMS",
	GUILD: "/guild",
	GUILD_CREATE: "/guild/create",
	MENU: "/menu",
	REVIEW_PAGE_1: "/review/page1",
	FAQ_PAGE_1: "/faq/page1",
	CHAT: "/chat",
	DONATE: "/donate",
}

const subscriptionCheckIgnore = [];

export const subscriptionRequiredURLs = Object.values(RouterLinks)
	.filter(s => !subscriptionCheckIgnore.includes(s));
