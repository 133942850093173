import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";

const tg = window?.Telegram?.WebApp || null;
const { MainButton, BackButton } = tg;

const useBackButtonModel = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const _backButtonToken = location?.state?.parentToken || null;

	const _isVer2 = useMemo(() => {
		return new URLSearchParams(location?.search).get('ver')?.trim() === "2" || null
	}, [location]);

	const backButtonHandler = () => {
		if (!_backButtonToken) return;

		navigate({
			pathname: `/menu`,
			search: `?token=${_backButtonToken}${_isVer2 ? `&ver=2` : ''}`,
		}, {
			replace: true,
			state: {
				subMenuCategory: location?.state?.subMenuCategory || null
			},
		});
	}
	useEffect(() => {
		if (!_backButtonToken) {
			MainButton.isVisible = false;
		}

		if (!location?.pathname?.includes("trainingHeroesGS") && !["/guild", "/webUploadScreensMS", "/account/edit"].includes(location?.pathname)) {
			BackButton.isVisible = !!_backButtonToken;

			BackButton.onClick(backButtonHandler);

			return () => {
				return BackButton.offClick(backButtonHandler);
			}
		}
	}, [_backButtonToken]);

	return {
		backButtonToken: _backButtonToken,
		isVer2: _isVer2
	}
}

export default useBackButtonModel;
