export const TYPE_TRAININGS = 1;
export const TYPE_UPDATE_HEROES = 2;
export const TYPE_UPDATE_TITANS = 3;
export const TYPE_GLOBAL_CHAMPIONSHIP = 5;
export const TYPE_REGISTRATION = 7;

// Guild Requests
// 1- согласие
// 2- отказ
// 3- отмена заявки

export const GR_TYPE_ACCEPT = 1;
export const GR_TYPE_DECLINE = 2;
export const GR_TYPE_CANCEL = 3;

// Guild Manage
// 1 - офицер
// 2 - генерал
// 3 - передать мг
// 4 - исключить
export const GM_PROMOTE_TO_OFFICER = 1;
export const GM_PROMOTE_TO_GENERAL = 2;
export const GM_PROMOTE_TO_MG = 3;
export const GM_KICK = 4;
export const GM_REQUEST_UPDATE_HEROES = 5;
export const GM_REQUEST_UPDATE_TITANS = 6;

export const GM_ID_PROMOTE = "GM_ID_PROMOTE";
export const GM_ID_PROMOTE_MG = "GM_ID_PROMOTE_MG";
export const GM_ID_KICK = "GM_ID_KICK";
export const GM_ID_REQUEST_UPDATE_HEROES = "GM_ID_REQUEST_UPDATE_HEROES";
export const GM_ID_REQUEST_UPDATE_TITANS = "GM_ID_REQUEST_UPDATE_TITANS";
export const GM_ID_SET_MAIN = "GM_ID_SET_MAIN";
export const GM_ID_GET_GUILD_CHATS_DATA = "GM_ID_GET_GUILD_CHATS_DATA";
export const GM_ID_SET_GUILD_CHATS_DATA = "GM_ID_SET_GUILD_CHATS_DATA";
export const GM_ID_GUILD_CREATE = "GM_ID_GUILD_CREATE";
export const GM_ID_GUILD_BEFORE_CREATE = "GM_ID_GUILD_BEFORE_CREATE";
export const GM_ID_GUILD_LEAVE = "GM_ID_GUILD_LEAVE";
export const GM_ID_SHOW_TITANS = "GM_ID_SHOW_TITANS";
export const GM_ID_SHOW_HEROES = "GM_ID_SHOW_HEROES";
export const GM_ID_GUILD_SERVER_CHANGE = "GM_ID_GUILD_SERVER_CHANGE";
export const GM_ID_GUILD_SERVER_CHANGE_MEMBERS = "GM_ID_GUILD_SERVER_CHANGE_MEMBERS";
export const GM_ID_GUILD_DELETE = "GM_ID_GUILD_DELETE";
export const GM_ID_GUILD_REQUEST_WG_HIT = "GM_ID_GUILD_REQUEST_WG_HIT";
export const GM_ID_GUILD_REQUEST_GC_HIT = "GM_ID_GUILD_REQUEST_GC_HIT";
export const GM_ID_GUILD_REQUEST_TITANIT = "GM_ID_GUILD_REQUEST_TITANIT";
export const GM_ID_GUILD_REQUEST_ACTIVITY = "GM_ID_GUILD_REQUEST_ACTIVITY";


// Account events
export const CHECK_ID_ACCOUNT_EVENT = "CHECK_ID_ACCOUNT_EVENT";
export const ACCOUNT_DELETE_EVENT = "ACCOUNT_DELETE_EVENT";
export const IS_ID_ACCOUNT_AVAILABLE = "isIdAccountAvailable";

export const IS_NAME_AVAILABLE = "isNameAvailable";
export const ACCOUNT_TRANSFER_ADD = "ACCOUNT_TRANSFER_ADD";
export const ACCOUNT_TRANSFER_CANCEL = "ACCOUNT_TRANSFER_CANCEL";

// Guild search types
export const TYPE_GUILD_ADD_NEW_REQUEST = "TYPE_GUILD_ADD_NEW_REQUEST";
export const TYPE_GUILD_ANSWER_REQUEST = "TYPE_GUILD_ANSWER_REQUEST";

export const HEROES_WAR_ADD = "HEROES_WAR_ADD";
export const TITANS_WAR_ADD = "TITANS_WAR_ADD";
export const HEROES_WAR_DELETE = "HEROES_WAR_DELETE";
export const TITANS_WAR_DELETE = "TITANS_WAR_DELETE";

// Review
export const REVIEW_PAGE_1_C_GET = "REVIEW_PAGE_1_C_GET";
export const REVIEW_PAGE_1_C_DATA_GET = "REVIEW_PAGE_1_C_DATA_GET";
export const REVIEW_PAGE_1_C_P_INFO = "REVIEW_PAGE_1_C_P_INFO";
export const REVIEW_PAGE_1_C_DELETE = "REVIEW_PAGE_1_C_DELETE";
export const REVIEW_PAGE_1_C_READ_MSG = "REVIEW_PAGE_1_C_READ_MSG";
export const REVIEW_PAGE_1_C_CHECK_STATUS = "REVIEW_PAGE_1_C_CHECK_STATUS";

// Скриншоты
export const UPLOAD_SCREENS_MS = "MS";
export const UPLOAD_SCREENS_HEROES_POWER = "UPLOAD_SCREENS_HEROES_POWER";
export const FIX_TOKEN = "FIX_TOKEN";

export const CP_SCREEN_SEND = 'CP_SCREEN_SEND';

// Buttons
export const ATTACK_TYPE = "ATTACK_TYPE"
export const DEFENSE_TYPE = "DEFENSE_TYPE"
