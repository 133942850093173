export const STATUS_NEW = 0;
export const STATUS_DB_RECEIVED_DATA = 1;
export const STATUS_IN_PROGRESS = 2;
export const STATUS_DONE = 3;
export const STATUS_ERROR = 4;

const STATUSES = {
	[STATUS_NEW]: "STATUS_NEW",
	[STATUS_DB_RECEIVED_DATA]: "STATUS_DB_RECEIVED_DATA",
	[STATUS_IN_PROGRESS]: "STATUS_IN_PROGRESS",
	[STATUS_DONE]: "STATUS_DONE",
	[STATUS_ERROR]: "STATUS_ERROR"
}

export const getStatusString = (status) => {
	return String(status);
}

export const getStatusText = (status) => {
	return STATUSES[status] || null;
}
