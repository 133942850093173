import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

const tg = window?.Telegram?.WebApp || null;
const getInitialLanguage = () => {
	let whereToGetLanguage = tg?.initDataUnsafe?.user?.language_code;
	return whereToGetLanguage === "ru" ? "ru" : "en";
}

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: getInitialLanguage(),
		fallbackLng: "ru",
		debug: true,
		react: {
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b', 's', 'a'],
			useSuspense: true
		},
		interpolation: {
			escapeValue: false,
		},
		backend: {
			requestOptions: {
				cache: 'no-store'
			}
		}
	}, () => {
		console.log('translation loaded');
	});

export default i18n;
