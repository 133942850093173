import React from "react";
import TextTemplate from "./common/TextTemplate";
import { LOADING_DOTS_TEXT, REQUEST_ERROR } from "../constants/texts";

const ErrorScreen = ({ token = null, dbError = null, isLoading = false, needNewRequest = false }) => {
    if (!token || token.length !== 36) {
        return <TextTemplate text={REQUEST_ERROR()}/>
    }

    if (isLoading) {
        return <TextTemplate text={LOADING_DOTS_TEXT()}/>
    }

    if (dbError) {
        return <TextTemplate text={dbError} needNewRequest={needNewRequest}/>
    }
}

export default ErrorScreen;
