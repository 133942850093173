import i18n from "i18next";

export const COMMON_ERROR_DEVELOPER_TEXT = () => i18n.t('errors.developer_error');
export const COMMON_SUCCESS_TEXT = () => i18n.t('alerts.success');
export const COMMON_NO_DATA_TEXT = () => i18n.t('globals.no_data');
export const COMMON_ATTACK_TEXT = () => i18n.t('globals.attack');
export const COMMON_DEFENCE_TEXT = () => i18n.t('globals.defense');
export const COMMON_SUPER_TITAN_TEXT = () => i18n.t('globals.supertitan');
export const POWER_TEXT = () => i18n.t('globals.power');
export const SEND_TEXT = () => i18n.t('globals.send');
export const CLOSE_TEXT = () => i18n.t('globals.close');
export const REQUEST_FAILED_ERROR = () => i18n.t('errors.request_failed_error')
export const REQUEST_ERROR = () => i18n.t('errors.request_error')

export const GUILD_MASTER = () => i18n.t('guild.role_master');
export const GUILD_GENERAL = () => i18n.t('guild.role_general');
export const GUILD_OFFICER = () => i18n.t('guild.role_officer');

export const REQUEST_SEND = () => i18n.t('info.request_send');
export const LOADING_DOTS_TEXT = () => `${i18n.t('globals.loading')}...`;

export const YES_TEXT = () => i18n.t('buttons.yes');
export const NO_TEXT = () => i18n.t('buttons.no');

export const ARE_U_SURE_TEXT = () => i18n.t('info.are_u_sure');
export const CANCEL_TEXT = () => i18n.t('buttons.cancel');
export const BACK_TEXT = () => i18n.t('buttons.cancel');
export const ATTENTION_TEXT = () => i18n.t('globals.attention');
export const YES_DELETE_TEXT = () => i18n.t('buttons.yes_delete');
export const CHANGE_TEXT = () => i18n.t('buttons.change');
export const SAVE_TEXT = () => i18n.t('buttons.save');
export const NO_DATA_TEXT = () => i18n.t('globals.no_data');
export const HEROES_TITLE_TEXT = () => i18n.t('globals.heroes');
export const TITANS_TITLE_TEXT = () => i18n.t('globals.titans');
export const DELETE_TEXT = () => i18n.t('buttons.delete');
