const tg = window?.Telegram?.WebApp || null;

function getQueryParamsFromURL(urlString) {
	const url = new URL(urlString);
	return Object.fromEntries(url.searchParams.entries());
}

export function constructURLWithParams(url, params) {
	const searchParams = new URLSearchParams(params);
	return `${url}?${searchParams.toString()}`;
}

export const _getRequest = async (url = null) => {
	if (!url) return;

	const queryParams = {
		info: btoa(tg?.initData) || null
	};

	const _isVer2 = new URLSearchParams(window?.location?.search).get('ver')?.trim() === "2" || null;
	if (_isVer2) {
		queryParams.ver = "2";
	}

	url = constructURLWithParams(url, queryParams);

	const req = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	});

	const res = await req?.json();
	console.log('_getRequest res', res);

	return res;
}

export const _postRequest = async (url = null, dataObj = null, method = "POST") => {
	if (!url || !dataObj) return;


	const queryParams = {
		info: btoa(tg?.initData) || null
	}

	const _isVer2 = new URLSearchParams(window?.location?.search).get('ver')?.trim() === "2" || null;
	if (_isVer2) {
		queryParams.ver = "2";
	}

	url = constructURLWithParams(url, queryParams);

	const req = await fetch(url, {
		method: method,
		body: JSON.stringify(dataObj),
		headers: {
			"Content-Type": "application/json"
		}
	});

	const res = await req?.json();
	console.log('_postRequest res', res);

	return res;
}
