import { useEffect, useMemo, useState } from "react";
import { CHECK_SUBSCRIPTION_URL, MENU_CHECK_API_URL, QUEUE_ADD_API_URL } from "../../constants/urlConstants";
import {
	STATUS_DB_RECEIVED_DATA,
	STATUS_DONE,
	STATUS_ERROR,
	STATUS_IN_PROGRESS,
	STATUS_NEW
} from "../../constants/statuses";
import { useLocation } from "react-router-dom";
import { TYPE_GLOBAL_CHAMPIONSHIP } from "../../constants/types";
import { COMMON_ERROR_DEVELOPER_TEXT, REQUEST_FAILED_ERROR } from "../../constants/texts";
import { _postRequest } from "../../common/requests/_getRequest";
import { useTranslation } from "react-i18next";
import { subscriptionRequiredURLs } from "../../constants/subscription";

const tg = window?.Telegram?.WebApp || null;

const tgID = tg?.initDataUnsafe?.user?.id || null;

const useStatusModel = () => {
	const { t, i18n } = useTranslation();
	// State
	const location = useLocation();

	const [subscriptionChecked, setSubscriptionChecked] = useState(false);
	const [subscriptionCheckError, setSubscriptionCheckError] = useState(null);

	const [checkLoading, setCheckLoading] = useState(true);
	const [dbError, setDbError] = useState(null);
	const [status, setStatus] = useState(null);
	const [type, setType] = useState(null);
	const [resultJson, setResultJson] = useState([]);
	const [token, setToken] = useState(new URLSearchParams(location?.search).get('token')?.trim() || null);
	const [botUserID, setBotUserID] = useState(null);

	const memoBotUserID = useMemo(() => botUserID, [botUserID]);

	// Router
	const _isFromTgMenu = useMemo(() => {
		return new URLSearchParams(location?.search).get('from')?.trim() === "tgMenu" || null
	}, [location]);

	const _isVer2 = useMemo(() => {
		return new URLSearchParams(location?.search).get('ver')?.trim() === "2" || null
	}, [location]);

	const isLocal = useMemo(() => {
		return window?.location?.hostname === "localhost" && window?.location?.port === "3000";
	}, [location]);

	const isSubscriptionRequired = useMemo(() => {
		return subscriptionRequiredURLs?.includes(location?.pathname) ?? false;
	}, [location]);

	/**
	 * Проверка подписки
	 */
	const checkSubscription = () => {
		return new Promise((resolve, reject) => {
			if (!subscriptionChecked) {
				if (!isSubscriptionRequired || isLocal) {
					setSubscriptionChecked(true);
					resolve(true);
				} else {
					_postRequest(CHECK_SUBSCRIPTION_URL, [])
						.then(res => {
							if (res?.success) {
								console.log('!!!!isChecked!!!!!', res?.isSubscribed);
								resolve(true);
							} else {
								throw new Error(res?.error);
							}
						})
						.catch(e => {
							setSubscriptionCheckError("string" === typeof e ? e : (e?.message || COMMON_ERROR_DEVELOPER_TEXT()));
							console.error('check subscription error', e);
							reject(e);
						}).finally(() => {
						setSubscriptionChecked(true);
					});
				}
			} else {
				resolve(true);
			}
		});
	}

	const _getStatusModelMenu = () => {
		_postRequest(MENU_CHECK_API_URL, { tempID: tgID })
			.then(res => {
				if (typeof res === "string") {
					throw new Error(res);
				}

				if (res?.error) {
					throw new Error(res?.error);
				}

				if (!token) {
					setToken(res?.data?.wdq_token ?? null);
					// res.data.language = "ru"
					i18n.changeLanguage(res?.data?.language);

					if (!res?.data?.wdq_token ?? null) {
						setDbError(COMMON_ERROR_DEVELOPER_TEXT())
					}
				}

			})
			.catch(e => {
				let _msg = e?.message?.trim();

				if (_msg === "Failed to fetch") {
					_msg = t('globals.service_offline');
				}

				setDbError(_msg);
			})
			.finally(() => {
				setTimeout(() => {
					setCheckLoading(false);
				}, 350);
			})

	}

	const _getStatusModelDataDefault = (_token = null) => {
		if (!_token) {
			setDbError(t('info.action_is_prohibited'));
			return
		}
		_postRequest(QUEUE_ADD_API_URL, { token: _token, checkOnly: true })
			.then(res => {
				if (res?.error) {
					throw new Error(res?.error);
				}

				let result = null;
				try {
					result = Object.entries(res?.data[1][0][0])[0][1] || null;
					result = JSON.parse(result);
					setStatus(result?.status);
					setType(result?.type);
					setBotUserID(result?.user_id ?? null);
					// result.language = "ru"
					i18n.changeLanguage(result?.language);
				} catch (e) {
					console.log('get_json_error', e);
					throw new Error(REQUEST_FAILED_ERROR());
				}

				// Общий процессинг
				if (result?.type === STATUS_NEW) {
					setDbError(null);
					return true;
				}

				if ([STATUS_DB_RECEIVED_DATA, STATUS_IN_PROGRESS].includes(result?.status)) {
					setDbError(result?.json?.text || t('info.request_in_progress'));
					return true;
				}

				// Глобальный чемпионат
				if (result?.type === TYPE_GLOBAL_CHAMPIONSHIP) {
					if (result?.json?.length > 0 && result?.status === STATUS_DONE) {
						setResultJson(result?.json);
						return true;
					}
				}

				if ([STATUS_DONE, STATUS_ERROR].includes(result?.status)) {
					const dbErrorText = result?.json?.text || t('info.request_processed');
					setDbError(dbErrorText);
					return true;
				}
			})
			.catch(e => {
				let _msg = e?.message?.trim();

				if (_msg === "Failed to fetch") {
					_msg = t('globals.service_offline');
				}

				setDbError(_msg);
			})
			.finally(() => {
				setTimeout(() => {
					setCheckLoading(false);
				}, 350);
			})
	}

	const startUp = async () => {
		const _token = new URLSearchParams(location?.search).get('token')?.trim() || null;

		if (_token) {
			setToken(_token);
		}

		// console.log('token', token);
		// console.log('_token', _token);

		setDbError(null);
		setType(null);
		setResultJson([]);

		if (_isFromTgMenu) {
			if (!tgID) {
				setDbError(`${t('errors.error_no_tg_id')}. ${COMMON_ERROR_DEVELOPER_TEXT()}`);
			} else {
				_getStatusModelMenu();
			}
		} else {
			_getStatusModelDataDefault(_token);
		}
	}

	// Checking status
	useEffect(() => {
		checkSubscription()
			.then(res => {
				if (res) {
					startUp();
				}
			}).catch(e => {
			console.log('startup error', e);
			setDbError("string" === typeof e ? e : (e?.message || COMMON_ERROR_DEVELOPER_TEXT()));
			setCheckLoading(false);
		})
	}, [location]);

	return {
		dbError,
		checkLoading,
		token,
		status,
		type,
		resultJson,
		isFromTgMenu: _isFromTgMenu,
		isVer2: _isVer2,
		setDbError,
		subscriptionCheckError,
		subscriptionChecked,
		botUserID: memoBotUserID
	}
}

export default useStatusModel;
