import { createContext, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import useStatusModel from "./components/hooks/useStatusModel";
import ErrorScreen from "./components/ErrorScreen";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import '@sweetalert2/themes/borderless/borderless.min.css';
import useBackButtonModel from "./components/hooks/useBackButtonModel";
import TextTemplate from "./components/common/TextTemplate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'moment/locale/ru';
import "./common/i18n";
import { useTranslation } from "react-i18next";
import { RouterLinks } from "./constants/subscription";

const tg = window?.Telegram?.WebApp || null;
tg.expand();

const App = () => {
	const { i18n } = useTranslation();

	const darkTheme = createTheme({
		palette: {
			mode: 'dark',
			primary: {
				main: '#008080',
			},
			secondary: {
				main: '#a40000',
			},
		},
		props: {
			MuiTooltip: {
				arrow: true,
			},
		},
		typography: {
			fontSize: 12,
			button: {
				letterSpacing: '0.05em',
			},
		},
		components: {
			MuiInputLabel: {
				defaultProps: {
					sx: {
						fontSize: "15px"
					},
				},
			}
		},
	});

	const {
		token,
		dbError,
		checkLoading,
		resultJson,
		isFromTgMenu,
		isVer2,
		subscriptionCheckError,
		subscriptionChecked,
		botUserID
	} = useStatusModel();

	const { backButtonToken } = useBackButtonModel();

	// Подписка
	if (subscriptionChecked && subscriptionCheckError) {
		return <TextTemplate text={subscriptionCheckError}/>;
	}

	// Меню и токен
	if (isFromTgMenu && !token) {
		if (dbError) {
			return <TextTemplate text={dbError}/>;
		}
		return <TextTemplate isLoadingOnly/>
	}

	// Загрузка и ошибки
	if (checkLoading || dbError) {
		return <ErrorScreen token={token} dbError={dbError} isLoading={checkLoading}/>
	}

	// Lazy
	const AccountEditLazy = lazy(() => import('./pages/AccountEdit'));
	const AccountRegistrationLazy = lazy(() => import('./pages/AccountRegistration'));
	const SimpleSelectHeroesLazy = lazy(() => import('./pages/SimpleSelectHeroes'));
	const HeroModifyLazy = lazy(() => import('./pages/HeroModify'));
	const WarAddLazy = lazy(() => import('./pages/WarAdd'));
	const GlobalChampionshipHeroesLazy = lazy(() => import('./pages/GlobalChampionshipHeroes'));
	const TitanModifyLazy = lazy(() => import('./pages/TitanModify'));
	const WebUploadScreensMSLazy = lazy(() => import('./pages/WebUploadScreensMS'));
	const GuildLazy = lazy(() => import('./pages/Guild'));
	const GuildCreateLazy = lazy(() => import('./pages/GuildCreate'));
	const MenuLazy = lazy(() => import('./pages/Menu'));
	const ReviewPage1Lazy = lazy(() => import('./pages/review/Page1'));
	const FAQ1Lazy = lazy(() => import('./pages/faq/FAQ1'));
	const SearchTeamPackLazy = lazy(() => import('./pages/SearchTeamPack'));
	const DonateLazy = lazy(() => import('./pages/Donate'));
	// const ChatLazy = lazy(() => import('./pages/chat/Chat'));

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language ?? "ru"}>
			<ThemeProvider theme={darkTheme}>
				<Toaster position="bottom-center" reverseOrder={false} toastOptions={{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					}
				}}/>

				<Suspense fallback={<TextTemplate isLoadingOnly/>}>
					<Routes>
						{/* Heroes */}
						<Route index element={<SimpleSelectHeroesLazy token={token}/>}/>
						<Route path={RouterLinks.TRAINING_HEROES_GS} element={<SimpleSelectHeroesLazy token={token}/>}/>
						<Route path={RouterLinks.UPDATE_HERO_POWER} element={<HeroModifyLazy token={token}/>}/>
						<Route path={RouterLinks.HEROES_WAR_ADD} element={<WarAddLazy token={token} isHeroes/>}/>
						<Route path={RouterLinks.GLOBAL_CHAMPIONSHIP_HEROES}
							   element={<GlobalChampionshipHeroesLazy token={token} resultJson={resultJson}/>}/>
						<Route path={RouterLinks.SEARCH_TEAM_PACK} element={<SearchTeamPackLazy token={token}/>}/>

						{/* Titans */}
						<Route path={RouterLinks.UPDATE_TITAN_POWER} element={<TitanModifyLazy token={token}/>}/>
						<Route path={RouterLinks.TITANS_WAR_ADD} element={<WarAddLazy token={token}/>}/>
						<Route path={RouterLinks.SEARCH_TEAM_PACK_TITAN}
							   element={<SearchTeamPackLazy token={token} isTitans/>}/>

						{/* Account */}
						<Route path={RouterLinks.ACCOUNT_REGISTRATION}
							   element={<AccountRegistrationLazy token={token}/>}/>
						<Route path={RouterLinks.ACCOUNT_EDIT} element={<AccountEditLazy token={token}/>}/>

						{/* Screenshots */}
						<Route path={RouterLinks.WEB_UPLOAD_SCREENS_MS}
							   element={<WebUploadScreensMSLazy token={token}/>}/>

						{/* Guild */}
						<Route path={RouterLinks.GUILD} element={<GuildLazy token={token}/>}/>
						<Route path={RouterLinks.GUILD_CREATE} element={<GuildCreateLazy token={token}/>}/>

						{/* Menu */}
						<Route path={RouterLinks.MENU}
							   element={<MenuLazy token={token} resultJson={resultJson} isVer2={isVer2}/>}/>

						{/* Review */}
						<Route path={RouterLinks.REVIEW_PAGE_1} element={<ReviewPage1Lazy token={token}/>}/>

						{/* FAQ */}
						<Route path={RouterLinks.FAQ_PAGE_1} element={<FAQ1Lazy token={token}/>}/>
						<Route path={RouterLinks.DONATE} element={<DonateLazy token={token} botUserID={botUserID}/>}/>
						{/*<Route path={MenuLinks.CHAT} element={<ChatLazy token={token}/>}/>*/}
					</Routes>
				</Suspense>
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export default App;
