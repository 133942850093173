import React from "react";
import Preloader from "./Preloader";
import { useTranslation } from "react-i18next";
import { REQUEST_ERROR } from "../../constants/texts";

const tg = window?.Telegram?.WebApp || null;

const TextTemplate = ({ isLoadingOnly = false, text = "", needNewRequest = false }) => {
    const { t } = useTranslation();
    const isLoading = isLoadingOnly || /(загрузка|loading)/i.test(text) || false;

    if (/undefined/i?.test(text)) {
        return <div className="isLoading" style={{ textAlign: "center" }}>
            <h2 style={{ margin: 20, color: 'white', textAlign: 'center' }}>
                {tg?.initDataUnsafe?.user?.language_code === "ru" ? "Загрузка..." : "Loading..."}
            </h2>
        </div>
    }

    if (text === REQUEST_ERROR()) {
        return <div className="isLoading" style={{ textAlign: "center" }}>
            <h2
                style={{ margin: 20, color: 'white', textAlign: 'center' }}
                dangerouslySetInnerHTML={{ __html: `${t('globals.home_info')}<br/><a style='color: teal; text-decoration: none' href='https://t.me/HWArcheryBot'/>Archery</a>` }}>
            </h2>
        </div>
    }

    return (
        <div className="isLoading">
            <h3
                style={{ margin: 20, color: 'white', textAlign: 'center' }}>
                {isLoading ? <Preloader/> :
                    <div className="error-db-text" dangerouslySetInnerHTML={{ __html: text }}/>}
                {needNewRequest && (<><br/>{t('globals.make_new_request')}</>)}
            </h3>
        </div>
    );
}

export default TextTemplate;
